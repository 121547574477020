/* global $, Foundation, MotionUI, List */
/* ==========================================================================
   Table of Contents
   ========================================================================== */


/*
    1.   General
    ---  Product Pages  ---
    2.1  Brand Pages
    2.2  Detail Pages
    ---  Gallery Pages  ---
    3.1  Gallery Landing Page
    3.3  Gallery Detail Page
    ---  Other Pages  ---
    4.1  Home Page
    4.2  Contact Page
    4.3  Article Page
*/
$(function App() {
  /* ==========================================================================
     1. General
     ========================================================================== */
  // Cache
  var $img = $('.parallax .img');
  var $heading = $('.parallax h1');
  var scroller;
  var wh = $(window).height();
  var intBrand = 0;
  var intWheel = 0;
  var intFinish = '';
  var decDiam = '0.0';
  var decWidth = '0.0';
  var strLug = '';
  var strPCD = '';
  var intOffStart = -130;
  var intOffEnd = 130;
  var intOffStartSel = -140;
  var intOstart = 0;
  var arrReturn = [];
  var strOffResult = '';
  var inventoryOptions;

  // Newsletter Validation
  /*$('form.ajax-form').submit(function formRequired(event) {
    var $this = $(this);
    var $requiredFields = $this.find('input[required]');
    var fail = false;
    var message = '';

    event.preventDefault();
    $requiredFields.each(function checkRequired(index, el) {
      if ($(el).val() === '') {
        fail = true;
        message = $(el).attr('data-error');
    });
    if (fail === true) {
      $('#newsletterModal .lead').text(message);
      $('#newsletterModal').foundation('open');
      return false;
    }
    // Submit via AJAX
    $.post($this.attr('action'), $this.serialize(), function submitForm(data) {
      $('#newsletterModal .lead').text(data);
      $('#newsletterModal').foundation('open');
    });
    return true;
  });*/


  $('.fitment-open').magnificPopup({
    items: {
      src: '#fitment-popup',
      type: 'inline'
    }
  });

  $('.checkFitment-open').magnificPopup({
    items: {
      src: '#checkFitment-popup',
      type: 'inline'
    }
  });

  $('#fitmentSubmit').on('click', function submitFitment() {
    var size = $('#size');
    if (size.val() && size.val() !== 'Select Size') {
      size.change();
    }
  });

  // Mobile Accordion
  if ($('.accordion').length && Foundation.MediaQuery.atLeast('medium')) {
    $('.accordion').each(function openAccordion() {
      $(this).foundation('down', $(this).find('.accordion-content').first(), true);
    });
  }

  // Scroll to section
  $('.scroll-to').on('click', function scrollToElem(event) {
    var target = $($(this).attr('href'));
    if (target.length) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000);
    }
  });

  // Varify captcha
  /*
  $('.rfqSubmit, .check-captcha').on('click', function () {
    if (grecaptcha.getResponse() == '') {
      var response = document.getElementById('check-captcha');
      response.innerHTML = 'Captcha box not selected';
      return false;
    } else {
      return true;
    }
  });*/

  $('.rfqSubmit, .check-captcha').on('click', function (e) {
    const form = e.target.closest('form');
    const captchaId = form.dataset.captchaId;
  console.log(captchaId)
    if (grecaptcha.getResponse(captchaId) === '') {
      var response = form.querySelector('#check-captcha');
      response.innerHTML = 'Captcha box not selected';
      return false;
    } else {
      const contactSubmit = form.querySelector('input[type="submit"]');
      console.log('contact submit', contactSubmit);
      form.requestSubmit(contactSubmit);
      return true;
    }
  });

  // header - content top spacing
  /* var getHeightOfNav = $('.header').outerHeight();
  $('.int.site-content').css({'padding-top':getHeightOfNav});*/
  // no more fixed header


  // force menu
  var forceMobileWidth = window.outerWidth;
  /* console.log(window.outerWidth);*/
  $(window).resize(function () {
    forceMobileWidth = window.outerWidth;
    /* console.log(forceMobileWidth);*/
    if (forceMobileWidth > 1024 && forceMobileWidth < 1400) {
      $('#forceMenu').css('display', 'block');
      $('#responsive-menu .top-bar-left > ul >li').css('display', 'block');
      /* $('#responsive-menu').css('display','none');*/
      /*      $('#forceMenu').click(function(){
              $('#responsive-menu').toggle();
            });*/
    } else if (forceMobileWidth > 1400) {
      $('#responsive-menu .top-bar-left > ul >li').css('display', 'table-cell');
      /* $('#responsive-menu').css('display','block');*/
    }
  });
  if (forceMobileWidth > 1024 && forceMobileWidth < 1400) {
    $('#forceMenu').css('display', 'block');
    $('#responsive-menu .top-bar-left > ul >li').css('display', 'block');
    /* $('#responsive-menu').css('display','none');*/
    /*    $('#forceMenu').click(function(){
          $('#responsive-menu').toggle();
        });*/
  } else if (forceMobileWidth > 1400) {
    $('#responsive-menu .top-bar-left > ul >li').css('display', 'table-cell');
    /* $('#responsive-menu').css('display','block');*/
  }


  /* ==========================================================================
     2.1 Brand Pages
     ========================================================================== */

  // Function for Wheel Filter to change page to 1 when a brand is selected
  // on a page greater than 1
  function replaceUrlParam(url, key, value) {
    if (value == null)
      value = '';
    var pattern = new RegExp('\\b(' + key + '=).*?(&|$)');
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + value + '$2');
    }
    return url + (url.indexOf('?') > 0 ? '&' : '?') + key + '=' + value;
  }

  // Wheel filters redirect
  $('#filters, #tireFilters').on('change', 'select', function (event) {
    event.preventDefault();
    // Get type of filter and current query
    var $this = $(this);
    var type = $this.attr('name');
    var query = window.location.search;
    var parts = document.location.href.split('/');

    // If this filter has already been applied, replace
    if (query.indexOf(type + '=') > -1) {
      var regex = new RegExp('(' + type + '=)([^&]+)?', 'ig');
      query = query.replace(regex, '$1' + $this.val());
      // If there are already other params
    } else if (query.indexOf('?') > -1) {
      query += '&' + type + '=' + $this.val();
      // If this is the only param
    } else {
      query += '?' + type + '=' + $this.val();
    }

    var elem = $this.parent().attr('id');
    // Set the page to 1
    if (window.location.href.match('page')) {
      query = replaceUrlParam(query, page, 1);
    }
    if ($this.parent().attr('id') == 'tireFilters') {
      if (window.location.href.match('tires/' + /\d+/) > -1) {
        window.location.href = '/tires/' + query;
      }
    } else if (window.location.href.match('wheels/' + /\d+/) > -1) {
      var urlSplit = document.location.href.split('/');
      var urlSection = urlSplit[4];
      if (urlSection > 0) {
        window.location.href = '/wheels/' + urlSection + '/' + query;
      } else {
        window.location.href = '/wheels/' + query;
      }
    } else {
      window.location.href = window.location.pathname + query;
    }
  });

  // PARALLAX EFFECT   http://codepen.io/sallar/pen/lobfp
  // requestAnimationFrame Shim
  window.requestAnimFrame = (function animFramPoly() {
    return window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function animTimout(callback) {
        window.setTimeout(callback, 1000 / 60);
      };
  }());

  // Scroller
  function Scroller() {
    this.latestKnownScrollY = 0;
    this.ticking = false;
  }

  Scroller.prototype = {
    // Initialize
    init: function init() {
      window.addEventListener('scroll', this.onScroll.bind(this), false);
    },

    // Capture Scroll
    onScroll: function onScroll() {
      this.latestKnownScrollY = window.scrollY;
      this.requestTick();
    },

    // Request a Tick
    requestTick: function requestTick() {
      if (!this.ticking) {
        window.requestAnimFrame(this.update.bind(this));
      }
      this.ticking = true;
    },

    // Update
    update: function update() {
      var currentScrollY = this.latestKnownScrollY;

      // Do The Dirty Work Here
      var imgScroll = currentScrollY / 2;
      var headScroll = currentScrollY / 3;

      this.ticking = false;

      $img.css({
        transform: 'translateY(' + imgScroll + 'px)',
        '-moz-transform': 'translateY(' + imgScroll + 'px)',
        '-webkit-transform': 'translateY(' + imgScroll + 'px)'
      });

      $heading.css({
        transform: 'translateY(' + headScroll + 'px)',
        '-moz-transform': 'translateY(' + headScroll + 'px)',
        '-webkit-transform': 'translateY(' + headScroll + 'px)'
      });
    }
  };

  // Attach!
  if ($('.parallax .img').length || $('.parallax h1').length) {
    scroller = new Scroller();
    scroller.init();
  }

  // Filter Categories
  $('.brand-nav').change(function changeBrand() {
    window.location.replace($(this).val());
  });
  // Quick View Popup
  $('.quickview-open').click(function wheelsQuickview() {
    var $this = $(this);
    var finishName = $this.data('finishname');
    var wheelName = $this.data('wheelname');
    var brandName = $this.data('brandname');
    var wheelImage = $this.data('wheelimage');
    var wheelLink = $this.data('wheellink');
    var wheelSizes = $this.data('sizes');
    $('#quickview-popup')
      .find('.popup-brandname')
      .html(brandName);
    $('#quickview-popup')
      .find('.popup-wheelname')
      .html(wheelName);
    $('#quickview-popup')
      .find('.popup-wheelfinish')
      .html(finishName);
    $('#quickview-popup')
      .find('.popup-sizes')
      .html(wheelSizes);
    $('#quickview-popup')
      .find('.popup-wheelimage')
      .attr('src', wheelImage);
    $('#quickview-popup')
      .find('.popup-wheellink')
      .attr('href', wheelLink);


    $.magnificPopup.open({
      items: {
        src: '#quickview-popup',
        type: 'inline'
      }
    });
  });
  // Header Filters
  $('#filter-size').change(function changeMake() {
    $('#wheel-search form').submit();
  });
  $('#filter-finish').change(function changeMake() {
    $('#wheel-search form').submit();
  });






  /* ==========================================================================
     2.2 Detail Pages
     ========================================================================== */
  // Slider and popup for main image
  function initializeMainImage() {
    $('.main-image').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      }
    }).slick({
      arrows: false,
      asNavFor: '.alt-images',
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

    $('.alt-images').slick({
      arrows: false,
      asNavFor: '.main-image',
      dots: false,
      focusOnSelect: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1
    });
  }

  initializeMainImage();

  // RFQ Popup
  $('.open-quote-popup').magnificPopup({
    items: {
      src: '#quote-popup',
      type: 'inline'
    }
  });

  // Gallery View Popup
  $('.galleryView-open').on('click', function () {
    for (var dataName in $(this).data()) {
      if ($(this).data()[dataName] !== '') {
        if (dataName === 'wheelimage') {
          $('#galleryView-popup img').attr('src', $(this).data()[dataName]);
        } else {
          var htmlString = '';
          htmlString = dataName;
          htmlString += ': ';
          htmlString += $(this).data()[dataName];
          htmlString += '<br/>';
          htmlString = htmlString.replace(/car: /, '').replace(/size/, ' size');
          $('.galleryView-info').find('.popup-' + dataName).html(htmlString);
        }
      } else {
        $('.galleryView-info').find('.popup-' + dataName).html('');
      }
    }
    $.magnificPopup.open({
      items: {
        src: '#galleryView-popup',
        type: 'inline'
      }
    });
  });

  // Show/Hide excess paragraph lines
  function getRows(selector) {
    var height = $(selector).height();
    var lineHeight = $(selector).css('line-height');
    var rows = height / lineHeight;
    height = parseFloat(height);
    lineHeight = parseFloat(lineHeight);
    return Math.round(rows);
  }

  // Show/Hide excess information
  $(document).ready(
    function toggleInfo() {
      if ($('.wheel-galleryImages > li').length > 6) {
        $('.wg-Show').removeClass('hidden');
      }
      if (getRows('.info--limit') > 5) {
        $('.info--limit').addClass('info--height');
        $('.info-Show').removeClass('hidden');
      }
    });

  function toggleButtons(classSelect, cssSelect, showBtnToggle, hideBtnToggle) {
    $(classSelect).toggleClass(cssSelect);
    $(showBtnToggle).toggleClass('hidden');
    $(hideBtnToggle).toggleClass('hidden');
  }

  $('.info-Show, .info-Hide').on('click', function toggleParagraph() {
    toggleButtons('.info--limit', 'info--height', '.info-Show', '.info-Hide');
  });
  $('.wg-Show, .wg-Hide').on('click', function toggleGallery() {
    toggleButtons('.wheel-galleryImages > li', 'wheel-galleryList', '.wg-Show', '.wg-Hide');
  });

  // Wheel gallery images
  $('.wheel-gallery-photo').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    },
    image: {
      markup: '<div class="wheel-gallery-figure mfp-figure">' +
        '<div class="mfp-close"></div>' +
        '<div class="mfp-img"></div>' +
        '<div class="mfp-bottom-bar">' +
        '<div class="mfp-title"></div>' +
        '<div class="mfp-counter"></div>' +
        '</div>' +
        '</div>',
      titleSrc: 'data-html'
    }
  });

  // Get see on vehicle image
  function detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk) {
    var urlOptions = [
      'year=' + year,
      'make=' + make,
      'model=' + model,
      'drivebody=' + encodeURIComponent(drivebody),
      'submodel=' + submodel,
      'wheel=' + wheel,
      'color=' + color,
      'fmk=' + fmk,
      'ID=' + configid
    ];
    try {
      $.getJSON('https://www.iconfigurators.app/ap-json/ap-image-fgap-fmk-part.aspx?' + urlOptions.join('&'), function getCarImage(data) {
        if (data.Result > 0) {
          car.find('img').attr('src', data.img[0].src);

          $('#vehicle-colors').html('');
          var colorIds = data.img[0].colorID;
          var colorNames = data.img[0].colorName;

          for (var index = 0; index < colorIds.length; index++) {
            if (colorIds[index] == color) {
              $('#vehicle-colors').append('<option value="' + colorIds[index] + '" selected>' + colorNames[index] + '</option>');
            } else {
              $('#vehicle-colors').append('<option value="' + colorIds[index] + '">' + colorNames[index] + '</option>');
            }
          }

          // Create body style select
          $('#vehicle-body').html('');
          var bodyNames = data.img[0].BodyStyle;

          for (var j = 0; j < bodyNames.length; j++) {
            if (bodyNames[j] === body) {
              $('#vehicle-body').append('<option data-id="' + j + '" value ="' + bodyNames[j] + '" selected>' + bodyNames[j] + '</option>');
            } else {
              $('#vehicle-body').append('<option data-id="' + j + '" value ="' + bodyNames[j] + '" >' + bodyNames[j] + '</option>');
            }
          }

          $('#see-on-vehicle-detail > a').attr('href', function replaceUrl(i, a) {
            return a.replace(/(&body=)([0-9]+)?(&color=)([0-9]+)?/ig, '$1' + $('#vehicle-body').find(':selected').data('id') + '$3' + $('#vehicle-colors').val());
          });

        } else {
          car.find('.img-wrapper')
            .addClass('no-fitment')
            .find('.error')
            .text('We do not currently have an image of this wheel on your vehicle. Check back soon.');
        }
      });
    } catch (err) {
      car.find('.img-wrapper')
        .addClass('no-fitment')
        .find('.error')
        .text('Error Loading Image for Your Vehicle');
    }
  }

  // Set Dropdowns
  function setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk) {
    // Bind the color select
    $('#vehicle-colors').change(function changeColor() {
      var body = $('#vehicle-body').val();
      var color = $('#vehicle-colors').val();
      var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
      $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
        return a.replace(/(&color=)([0-9]+)?/ig, '$1' + color);
      });
      detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk);
    });
    // Bind the body select
    $('#vehicle-body').change(function changeBody() {
      var body = $('#vehicle-body').val();
      var color = 0;
      var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
      $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
        return a.replace(/(&body=)([0-9]+)?(&color=)([0-9]+)?/ig, '$1' + $('#vehicle-body').find(':selected').data('id') + '$3');
      });
      detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk);
    });
  }

  // Check if vehicle is set in session first
  if ($('#see-on-vehicle-detail').length) {
    var car = $('#see-on-vehicle-detail');
    var year = car.data('year');
    var make = car.data('make');
    var model = car.data('model');
    var drivebody = car.data('drivebody');
    var submodel = car.data('submodel');
    var wheel = car.attr('data-wheel');
    var configid = car.data('config');
    var option = car.data('option');
    var fmk = car.data('option');
    if (typeof option === 'undefined') {
      option = '';
    }
    detailCarImage(car, year, make, model, drivebody, submodel, wheel, 0, '', configid, fmk);
    setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk);
  }

  // Click on finish images
  $('.detail-thumbs a').click(function changeFinish(e) {
    // e.preventDefault();
    var $this = $(this);
      
    console.log($this.data('id'));
    // Update main image with new finish
    $.get('/ajax/getFinishImages.cfm?id=' + $this.data('id'), function getFinishes(data) {
      $('#detail-top .images').html(data);
      initializeMainImage();
      $('.brand-details_lugs').text($this.data('lugs') + ' Lug');
    });
    $('#finish').text($this.find('.finish-name').text());

    // Replace see on image vehicle
    if ($('#see-on-vehicle-detail').length) {
      car = $('#see-on-vehicle-detail');
      car.attr('data-wheel', $this.attr('data-wheel'));
      year = car.data('year');
      make = car.data('make');
      model = car.data('model');
      drivebody = car.data('drivebody');
      submodel = car.data('submodel');
      wheel = $this.attr('data-wheel');
      configid = car.data('config');
      option = car.data('option');
      fmk = car.data('option');
      var color = $('#vehicle-colors').val();
      var body = $('#vehicle-body').val();
      if (typeof option === 'undefined') {
        option = '';
      }

      car.children('a').attr('href', function replaceUrl(i, a) {
        return a.replace(/(wheelimage=)([0-9]+)?/ig, '$1' + $this.data('id'));
      });

      if (!$this.attr('data-wheel')) {
        car.find('.img-wrapper')
          .addClass('no-fitment')
          .find('.error')
          .text('Current finish/lug does not fit selected vehicle');
      } else {
        detailCarImage(car, year, make, model, drivebody, submodel, wheel, 0, '', configid, fmk);
        // detailCarImage(car, year, make, model, submodel, wheel, color, body, configid, option);
        car.find('.img-wrapper')
          .removeClass('no-fitment');
      }

      // setDropdownHandlers(car, year, make, model, submodel, configid, option);
      setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk);
    }
  });

  /* ==========================================================================
     3.1  Gallery Landing Page
     ========================================================================== */
  $('#gallery a.video').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });

  /* ==========================================================================
     3.2  Gallery Detail Page
     ========================================================================== */
  $('#gallery-main-image').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
  });

  $('#gallery-main-image').slick({
    adaptiveHeight: false,
    arrows: false,
    asNavFor: '#gallery-thumbs',
    fade: true,
    lazyLoad: 'ondemand'
  });
  $('#gallery-thumbs').slick({
    arrows: false,
    asNavFor: '#gallery-main-image',
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToScroll: 3,
          slidesToShow: 3
        }
      }
    ]
  });

  /* ==========================================================================
     4.1  Home Page
     ========================================================================== */
  /* MOTION UI - FADE IN TRANSITIONS /////////*/
  if (Foundation.MediaQuery.atLeast('medium')) {
    $(window).load(function checkLoad() {
      $('.animate').each(function animateItem() {
        var $this = $(this);
        if (($this.offset().top - $(window).scrollTop()) - wh <= -350) {
          MotionUI.animateIn($this, 'fade-in', function adjustOpacity() {
            $this.css('opacity', '1');
          });
          $this.removeClass('animate');
        }
      });
    });
    $(window).scroll(function checkScroll() {
      $('.animate').each(function animateItem() {
        var $this = $(this);
        if (($this.offset().top - $(window).scrollTop()) - wh <= -350) {
          MotionUI.animateIn($this, 'fade-in', function adjustOpacity() {
            $this.css('opacity', '1');
          });
          $this.removeClass('animate');
        }
      });
    });
  }

  /* MOTION UI - RANDOM TRANSITIONS /////////*/
  if (Foundation.MediaQuery.atLeast('medium')) {
    $(window).scroll(function scrollPage() {
      $('.animate-random').each(function animateElem() {
        var $this = $(this);
        var transitions = ['slide-in-left', 'slide-in-up', 'slide-in-right', 'fade-in',
          'hinge-in-from-right', 'hinge-in-from-bottom', 'hinge-in-from-left'];
        var randomNum = Math.floor(Math.random() * (transitions.length - 1));
        if (($this.offset().top - $(window).scrollTop()) - wh <= 0) {
          MotionUI.animateIn($this, transitions[randomNum]);
          $this.removeClass('animate-random');
        }
      });
    });
  }

  // Homepage slider
  // $('.hp-slider').slick({
  //   autoplay: true,
  //   dots: true,
  //   arrows: false,
  //   fade: true,
  //   cssEase: 'linear',
  //   pauseOnHover: 'false'
  // });

  // // Instafeed Widget
  // if ($('#instafeed').length > 0) {
  //   var feed = new Instafeed({
  //     clienID: 'c8cc0aa206904d4fbcfb1afbd069f6bf',
  //     get: 'user',
  //     userId: 3088546141,
  //     accessToken: '3088546141.1677ed0.46b56deb85434f839889ca2b9e358e47',
  //     limit: 8,
  //     resolution: 'standard_resolution',
  //     /* template: "<div class=\"column animate\"><a href=\"{{image}}\" class=\"instainfo\" target=\"_blank\" data-caption=\"{{caption}}\" data-username=\"{{model.user.username}}\" data-profilepic=\"{{model.user.profile_picture}}\" data-bio=\"{{model.user.bio}}\" data-link=\"{{link}}\"><img src=\"{{image}}\"></a></div>",*/
  //     template: "<div class=\"column\"><a href=\"{{image}}\" class=\"instainfo\" target=\"_blank\" data-caption=\"{{caption}}\" data-username=\"{{model.user.username}}\" data-profilepic=\"{{model.user.profile_picture}}\" data-bio=\"{{model.user.bio}}\" data-link=\"{{link}}\" style=\"background-image:url('{{image}}');\"></a></div>",
  //     after: function () {
  //       $('.instainfo').click(function instaPopup(e) {
  //         // prevent hyperlink
  //         e.preventDefault();
  //         // setup variables from instagram data
  //         var $this = $(this);
  //         var $getUsername = $this.data('username');
  //         var $getCaption = $this.data('caption');
  //         var $getProfilePic = $this.data('profilepic');
  //         var $getBio = $this.data('bio');
  //         var $getLink = $this.data('link');
  //         var $getInstagramLink = '//www.instagram.com/visionwheel/';
  //         var $getImage = $this.attr('href');

  //         // implement into popup
  //         $('#instagram-popup')
  //           .find('.instagram-popup-profilepic')
  //           .attr('src', $getProfilePic);
  //         $('#instagram-popup')
  //           .find('.instagram-popup-page')
  //           .attr('href', $getInstagramLink);
  //         $('#instagram-popup')
  //           .find('.instagram-popup-username')
  //           .html($getUsername)
  //           .attr('href', $getInstagramLink);
  //         $('#instagram-popup')
  //           .find('.instagram-popup-link')
  //           .attr('href', $getLink);
  //         $('#instagram-popup')
  //           .find('.instagram-popup-img')
  //           .attr('src', $getImage);
  //         $('#instagram-popup')
  //           .find('.instagram-popup-caption')
  //           .html($getCaption);

  //         // run magnific popup with variables set
  //         $.magnificPopup.open({
  //           /* items:items,*/
  //           items: {
  //             src: '#instagram-popup',
  //             type: 'inline'
  //           },
  //           gallery: {
  //             enabled: true,
  //             navigateByImgClick: true,
  //             preload: [0, 1]
  //           }
  //         });
  //       });
  //     }
  //   });
  //   feed.run();
  // }

  // Fixed Mid Nav
  if ($('.hp-mid-nav').length) {
    var sliderContainer = $('.hp-mid-nav');
    console.log(sliderContainer);
    sliderOffset = sliderContainer.offset();
    var sliderOffsetTop = sliderOffset.top;
    var sliderContainerHeight = sliderContainer.outerHeight(true) + sliderOffsetTop;

    $(window).scroll(function () {
      doc = $(this);
      dist = $(this).scrollTop();

      if (dist >= sliderOffsetTop) {
        $('.hp-mid-nav').show();
        $('.hp-mid-nav').css({
          'position': 'fixed',
          'top': 0,
          'opacity': 1
        });
        $('.hp-about').css({
          'padding-top': '200px'
        });
      } else {
        $('.hp-mid-nav').css({
          'position': 'static',
          'opacity': 0
        });
        $('.hp-about').css({
          'padding-top': '0px'
        });
      }
    });
  }

  /* /// Newsletter popup ///*/
  if (document.getElementById('newsletter-popup')) {
    if (!readCookie('popup')) {
      $.magnificPopup.open({
        items: {
          src: '#newsletter-popup',
          type: 'inline'
        }
      });
      createCookie('popup', 'newsletter', 7);
    } else if (!readCookie('popup') == 'newsletter') {
      createCookie('popup', 'newsletter', 7);
    }
  }

  // create cookie (for newsletter popup)
  // key-value;expiration_date;path;domain;
  function createCookie(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = '; expires=' + date.toGMTString();
    }
    else var expires = '';
    document.cookie = name + '=' + value + expires + '; path=/';
  }

  // Get Cookie (for Newsletter Popup)
  function readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }





  /* ==========================================================================
     4.2  Contact Page
     ========================================================================== */
  $('#vchrCountry select').change(function changeCountry() {
    $.get('/ajax/getStates.cfm?country=' + $(this).val(), function getStates(data) {
      $('#vchrState select').html(data).prop('disabled', false);
    });
  });
  $('#form-left form').submit(function checkRequired() {
    var fail = false;
    var message = '';
    var errorCount = 0;
    var name = '';
    var title = '';
    $('#form-left form [required]').each(function checkFields(index, element) {
      var $element = $(element);
      if ($element.val() === '') {
        $element.css('background', 'red');
        fail = true;
        errorCount++;
        name = $element.siblings('label').replace('*', '');
        message += name + ' is required. ';
      }
    });
    if (fail) {
      title = $(this).attr('data-title');
      $('#modal').html('<p>Form submission failed for the following reason(s):' + message + '</p>')
        .dialog({
          minHeight: 150,
          width: 300,
          modal: true,
          title: title,
          closeText: 'X',
          buttons: {
            Okay: function closeModal() {
              $(this).dialog('close');
            }
          }
        });
      return false;
    }
    return true;
  });

  //accordion
  //document.getElementsByClassName("accordion-title")[0].click();
  //document.getElementsByClassName("accordion-title")[0].click();
  if($('#contact').length){
    let accordionItems = document.querySelectorAll('.is-active');
    let accordionContents = document.querySelectorAll('#contact .accordion-content');
    for (let i = 0; i < accordionItems.length; i++){
      accordionItems[i].classList.remove('is-active');
      console.log('done');
    }
    console.log(accordionItems);
    for (let i = 0; i < accordionContents.length; i++){
      accordionContents[i].style.removeProperty('display');
      accordionContents[i].classList.remove('shide');
      console.log('done dittity');
    }
  }
  /* ==========================================================================
     4.3  Inventory Page
     ========================================================================== */
  function getData(url, selector) {
    var i;
    $.get('/ajax/getData.cfm?' + url, function getInventory(data) {
      if (selector === '#offstart') {
        arrReturn = data.split(',');

        if (intOstart === 0) {
          intOffStart = isNaN(parseInt(arrReturn[0], 10)) ? -130 : parseInt(arrReturn[0], 10);
        } else {
          intOffStart = intOffStartSel + 10;
        }

        intOffEnd = isNaN(parseInt(arrReturn[1], 10)) ? 130 : parseInt(arrReturn[1], 10);
        strOffResult = '<option value="">All</option>';

        for (i = intOffStart; i <= intOffEnd; i += 10) {
          strOffResult += '<option value="' + i + '">' + i + '</option>';
          if (i >= 130) {
            break;
          }
        }

        if (intOstart === 0) {
          $(selector).html(strOffResult);
        }
        $('#offend').html(strOffResult);
      } else {
        $(selector).html(data);
      }
    });
  }

  if ($('#inventory').length) {
    $('#brand').on('change', function changeBrand() {
      var url = '';
      intBrand = ($(this).val() !== '') ? $(this).val() : 0;
      intOstart = 0;
      url = '&brand=' + intBrand;
      getData('step=1' + url, '#wheel');
      getData('step=2' + url, '#finish');
      getData('step=3' + url, '#diam');
      getData('step=4' + url, '#width');
      getData('step=5' + url, '#lugs');
      getData('step=6' + url, '#pcd');
      getData('step=7' + url, '#offstart');
    });

    $('#wheel').on('change', function changeWheel() {
      var url = '';
      intWheel = ($(this).val() !== '') ? $(this).val() : 0;
      intOstart = 0;
      url = '&brand=' + intBrand + '&wheel=' + intWheel;
      getData('step=2' + url, '#finish');
      getData('step=3' + url, '#diam');
      getData('step=4' + url, '#width');
      getData('step=5' + url, '#lugs');
      getData('step=6' + url, '#pcd');
      getData('step=7' + url, '#offstart');
    });

    $('#finish').on('change', function changeFinish() {
      var url = '';
      intFinish = ($(this).val() !== '') ? $(this).val() : '';
      url = '&brand=' + intBrand + '&wheel=' + intWheel + '&finish=' + intFinish;
      getData('step=3' + url, '#diam');
      getData('step=4' + url, '#width');
      getData('step=5' + url, '#lugs');
      getData('step=6' + url, '#pcd');
      getData('step=7' + url, '#offstart');
    });

    $('#diam').on('change', function changeDiam() {
      var url = '';
      decDiam = ($(this).val() !== '') ? $(this).val() : 0;
      intOstart = 0;
      url = '&brand=' + intBrand + '&wheel=' + intWheel + '&finish=' + intFinish +
        '&diam=' + decDiam;
      getData('step=4' + url, '#width');
      getData('step=5' + url, '#lugs');
      getData('step=6' + url, '#pcd');
      getData('step=7' + url, '#offstart');
    });

    $('#width').on('change', function changeWidth() {
      var url = '';
      decWidth = ($(this).val() !== '') ? $(this).val() : 0;
      intOstart = 0;
      url = '&brand=' + intBrand + '&wheel=' + intWheel + '&finish=' + intFinish +
        '&diam=' + decDiam + '&width=' + decWidth;
      getData('step=5' + url, '#lugs');
      getData('step=6' + url, '#pcd');
      getData('step=7' + url, '#offstart');
    });

    $('#lugs').on('change', function changeLug() {
      var url = '';
      strLug = ($(this).val() !== '') ? $(this).val() : '';
      intOstart = 0;
      url = '&brand=' + intBrand + '&wheel=' + intWheel + '&finish=' + intFinish +
        '&diam=' + decDiam + '&width=' + decWidth + '&lug=' + strLug;
      getData('step=6' + url, '#pcd');
      getData('step=7' + url, '#offstart');
    });

    $('#pcd').on('change', function changePCD() {
      var url = '';
      strPCD = ($(this).val() !== '') ? $(this).val() : '';
      intOstart = 0;
      url = '&brand=' + intBrand + '&wheel=' + intWheel + '&finish=' + intFinish +
        '&diam=' + decDiam + '&width=' + decWidth + '&lug=' + strLug + '&pcd=' + strPCD;
      getData('step=7' + url, '#offstart');
    });

    $('#offstart').on('change', function changeOffstart() {
      var url = '';
      intOffStartSel = ($(this).val() !== '') ? parseInt($(this).val(), 10) : -140;
      intOstart = 1;
      url = '&brand=' + intBrand + '&wheel=' + intWheel + '&finish=' + intFinish +
        '&diam=' + decDiam + '&width=' + decWidth + '&lug=' + strLug + '&pcd=' + strPCD +
        '&offSetStart=' + intOffStartSel;
      getData('step=7' + url, '#offstart');
    });
  }

  if ($('#inventory-results').length > 0) {
    inventoryOptions = {
      page: 5000,
      valueNames: ['partNum', 'brand', 'name', 'finish', 'diam', 'width', 'bolt', 'offset', 'qty']
    };
    /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "userList" }]*/
    var userList = new List('inventoryTable', inventoryOptions);

    $('.sort').on('click', function sortItems() {
      var $this = $(this);
      var icon = 'fa-caret-up';
      if ($this.find('i').hasClass('fa-caret-up')) {
        icon = 'fa-caret-down';
      } else {
        icon = 'fa-caret-up';
      }
      $('.sort i').remove();
      $this.append('<i class="fa ' + icon + '"></i>');
    });

    $('.image_link').magnificPopup({
      type: 'image'
    });
  }
});
  /* ==========================================================================
2.2 Accessories Pages
========================================================================== */

  
  /* ==========================================================================
     10  Racer
     ========================================================================== */
  
  
  $('#racer-gal-main-image').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
  });

  $('#racer-gal-main-image').slick({
    adaptiveHeight: true,
    arrows: false,
    asNavFor: '#racer-gal-thumbs',
    fade: true,
    lazyLoad: 'ondemand'
  });
  $('#racer-gal-thumbs').slick({
    arrows: false,
    asNavFor: '#racer-gal-main-image',
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToScroll: 3,
          slidesToShow: 3
        }
      }
    ]
  });


  $('.tire-lock .popup-youtube').magnificPopup({
    type: 'iframe'
  });


/* ==========================================================================
     News
  ========================================================================== */
  $('#article-gal-main-image').magnificPopup({
    delegate: 'a',
    type: 'image',
    arrows: false,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
  });

$('#article-gal-main-image').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        /// display current image count - .gal-detail-info-connt ///
        $('#currentSlide').text(nextSlide+1);
    }).slick({
    adaptiveHeight: true,
    arrows: false,
    asNavFor: '#article-gal-thumbs',
    fade: true,
    lazyLoad: 'ondemand'
  });

$('#article-gal-thumbs').slick({
    arrows: true,
    nextArrow: '<button class="article-next article-arrow" aria-label="Next" type="button"><i class="fa fa-caret-right"></i></button>',
    prevArrow: '<button class="article-prev article-arrow" aria-label="Prev" type="button"><i class="fa fa-caret-left"></i></button>',
    asNavFor: '#article-gal-main-image',
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToScroll: 3,
          slidesToShow: 3
        }
      }
    ]
  });